<template>
<!-- 页头区域 -->
<div :class="{ 'submenu_show': submenuShow, wrap_head: true }">
  <div class="head_con">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid cu-header-container">
        <a class="navbar-brand" href="#">NCloud 九云</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav top-menu" @mouseenter="submenuShow=true" @mouseleave="submenuShow=false">
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="/">首页</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">产品中心</a>
              <ul class="sub-menu-list sublist_show">
                <li class="nav-item">
                  <a class="nav-link" href="#">看起来</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">寐卡助手</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">核账助手</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">证件识别</a>
                </li>
              </ul>
            </li>
            <!-- <li class="nav-item">
              <router-link class="nav-link" to="/partners">用户案例</router-link>
            </li> -->
            <li class="nav-item">
              <a class="nav-link" href="https://admin.no-pms.com" target="_blank">售后支持</a>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/about">关于我们</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</div>

<router-view />

<div class="wrap_footer">
  <div class="footer_con">
    <div class="focus_us">
      <h3 class="title">关注我们</h3>
      <ul class="focus_list">
        <li>
          <a href="javascript:void(0)">
            <i class="bi bi-wechat" data-bs-container="body" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-placement="bottom" data-bs-html="true" :data-bs-content="wechatQRCode"></i>
            <!-- <img class="wechat-code" src="/img/index/wechat-code.jpg" alt="img"> -->
          </a>
        </li>
        <li>
          <a href="#">
            <i class="bi bi-linkedin"></i>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="bi bi-twitter"></i>
          </a>
        </li>
      </ul>
    </div>

    <div :class="{ join_us: true, sublist_show: sublistTitles[0] }">
      <!-- 添加类名 sublist_show 显示子菜单 -->
      <div class="tit_area" @click="subListToggle(0)">
        <h3 class="title">加入我们</h3>
        <i class="icon icon_arrow"></i>
      </div>
      <ul class="join_list">
        <li>
          <a href="javascript:void(0)">社会招聘</a>
        </li>
        <li>
          <a href="javascript:void(0)">校园招聘</a>
        </li>
        <li>
          <a href="javascript:void(0)">国际招聘</a>
        </li>
      </ul>
    </div>

    <div :class="{ contact_us: true, sublist_show: sublistTitles[1] }">
      <div class="tit_area" @click="subListToggle(1)">
        <h3 class="title">联系我们</h3>
        <i class="icon icon_arrow"></i>
      </div>
      <ul class="contact_list">
        <li>
          <a href="javascript:void(0)">客户服务</a>
        </li>
        <li>
          <a href="javascript:void(0)">合作洽谈</a>
        </li>
        <li>
          <a href="javascript:void(0)">硬件采购</a>
        </li>
        <li>
          <a href="javascript:void(0)">境外代理</a>
        </li>
      </ul>
    </div>

    <div :class="{ legal_info: true, sublist_show: sublistTitles[2] }">
      <div class="tit_area" @click="subListToggle(2)">
        <h3 class="title">法律信息</h3>
        <i class="icon icon_arrow"></i>
      </div>
      <ul class="legal_list">
        <li>
          <a href="javascript:void(0)">服务协议</a>
        </li>
        <li>
          <a href="javascript:void(0)">隐私政策</a>
        </li>
        <li>
          <a href="javascript:void(0)">知识产权</a>
        </li>
      </ul>
    </div>

    <div class="logo_9cloud">
      <img src="./assets/9cloud_logo.png" alt="NCloud九云信息" style="height: 28px;">
      <span>九云信息</span>
    </div>

    <div class="footer_area">
      <ul class="links">
        <li>
          <a href="#">法律声明</a>
        </li>
        <li>
          <a href="#">网站地图</a>
        </li>

        <li class="num">
          <a href="http://beian.miit.gov.cn/">京ICP备17072322号</a>
        </li>
      </ul>
      <p class="copyright">
        <a href="http://beian.miit.gov.cn/" class="flag_num">京ICP备17072322号</a>
        Copyright © 2015 - {{ new Date().getFullYear() }} NCloud. All Rights Reserved. <br class="flag_m">九云信息 版权所有</p>
    </div>

    <a :class="{ 'ten_totop': true, 'ten_totop--float': true, 'back_to_top': true, 'ten_totop--float--show': backToTopShow }" @click="backToTop"></a>
  </div>
</div>
<!-- 页头区域 -->
</template>

<script>
import { Popover } from 'bootstrap'

function debounce (method, delay) {
  clearTimeout(method._tId)
  method._tId = setTimeout(function () {
    method()
  }, delay)
}

export default {
  name: 'App',
  data () {
    return {
      submenuShow: false,
      sublistTitles: [false, false, false],
      backToTopShow: false
    }
  },
  mounted () {
    window.onscroll = () => {
      debounce(this.scrollFunction, 100)
    }

    return new Popover(document.body, {
      selector: "[data-bs-toggle='popover']"
    })
  },
  computed: {
    wechatQRCode () {
      return `<div class="popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-body"><img src="${this.getImgUrl('qrcode_for_gh_f5f081c2a6c2_344.jpeg')}" width="100px"></div></div>`
    }
  },
  methods: {
    getImgUrl (pic) {
      return require('./assets/' + pic)
    },
    subListToggle (index) {
      this.sublistTitles[index] = !this.sublistTitles[index]
    },
    scrollFunction () {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        this.backToTopShow = true
      } else {
        this.backToTopShow = false
      }
    },
    backToTop () {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-light {
  --bs-bg-opacity: 0;
}

#navbarNav {
  justify-content: center;
}

.wrap_head {
  display: block;
  width: 100%;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.2s linear;
}

.head_con {
  max-width: 1200px;
  margin: 0px auto;
}

.top-menu > li.nav-item {
  width: 120px;
  text-align: center;
}

.top-menu > li.nav-item > a {
  font-weight: bold;
}

.navbar-light .navbar-nav .nav-link {
  color: white;
}

.submenu_show {
  background-color: white;
}

.submenu_show .navbar-light .navbar-nav .nav-link {
  color: #575959;
}

.submenu_show .navbar-light .navbar-nav .nav-link:hover {
  color: black;
}

.navbar-light .navbar-brand {
  &, &:hover {
    font-weight: bolder;
    color: white;
  }
}

.submenu_show .navbar-light .navbar-brand {
  &, &:hover {
    font-weight: bolder;
    color: #0f4c81;
  }
}

.sub-menu-list {
  list-style: none;
  padding: 0;
  text-align: center;
  display: none;
  // position: absolute;
  background-color: white;
  width: 120px;
}

li.nav-item:hover > ul.sub-menu-list {
  display: block;
  animation: fadeIn; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 1s; /* don't forget to set a duration! */
}

.navbar > .container-fluid.cu-header-container {
  align-items: baseline;
}

.navbar-collapse.collapse.show .nav-link{
  &, &:hover {
    color: black;
  }
}

.top-menu>.nav-item>.nav-link {
  display: block;
  height: 72px;
  line-height: 72px;
  padding: 0;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  opacity: 0.95;
  transition: color 0.3s linear;
  position: relative;
  z-index: 2;
}

.top-menu>.nav-item>.nav-link:hover {
  opacity: 1;
}

.top-menu>.nav-item>.nav-link:after {
  display:block;
  content:"";
  height: 4px;
  width: 80px;
  position: absolute;
  bottom: 12px;
  left: 20px;
  background: #0f4c81;
  opacity: 0;
  transition: transform 0.2s ease-out,opacity 0.2s ease-out;
  transform-origin: 50% 0%;
  transform: scale(0.01, 1);
}

.top-menu>.nav-item>.nav-link:before {
  display: none;
  content: "";
  height: 400px;
  width: 100%;
  position: absolute;
  top: 19px;
  left: -10px;
  background: #0f4c81;
  opacity: 1;
}

.top-menu>.nav-item:hover>.nav-link:after {
  transform:scale(1,1);
  opacity: 1;
}

// 页脚
.wrap_footer {
  width: 100%;
  height: 480px;
  background: #fbfbfb;
  position: relative;
  z-index: 1;
  color: #5f6464;

  a {
    outline: none;
    background: none;
    text-decoration: none;
  }

  .footer_con {
    max-width: 1200px;
    margin: 0px auto;
    height: 480px;
    position: relative;
  }

  .footer_con .title {
    display: block;
    font-size: 20px;
    color: #5f6464;
    font-weight: normal;
    margin-bottom: 30px;
    line-height: unset;
  }

  .footer_con ul li a {
    display:block;
    font-size: 16px;
    color: #5f6464;
    font-weight: normal;
    margin-bottom: 18px;
  }

  .focus_us {
    display: block;
    height: 55px;
    width: 100%;
    border-bottom: 1px solid #f2f3f5;
    padding-top: 33px;
    margin-bottom: 40px;
    box-sizing: content-box;
  }

  .focus_us .title {
    float: left;
    margin-right: 33px;
  }

  .focus_us .focus_list li {
    display: inline-block;
    margin-right: 28px;
  }
  .focus_us .focus_list li a {
    display: block;
    height: 28px;
    width: 28px;

    .bi {
      font-size: 20px;
    }
  }

  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .focus_us .focus_list li a:hover .bi-twitter { color: #00acee; }
  .focus_us .focus_list li a:hover .bi-linkedin { color: #0e76a8; }
  .focus_us .focus_list li a:hover .bi-wechat { color: #09b83e; }

  .join_us,.contact_us,.legal_info { float: left; width: 216px; }

  .logo_9cloud { display: flex; flex-direction: row; align-items: center; height: 28px; width: 212px; position: absolute; right: 75px; top: 50%; margin-top: -21px;}
  .logo_9cloud img{
    width: 46px;
  }

  .logo_9cloud span {
    font-size: 26px;
    font-weight: bold;
    color: #329ff6;
  }

  .footer_area{ display:block; width: 100%; position: absolute; bottom: 51px; left: 0px;}
  .footer_area .links li{ display:inline-block; float: left; margin-right: 20px;}
  .footer_area .links li a{ font-size: 14px; color: #5f6464; margin-bottom: 0px;}
  .footer_area .copyright{ font-size: 14px; color: #5f6464; float: left; width: 100%; margin-top: 12px;}
  .footer_area .copyright .flag_num{ display:none;}

  /*页脚移动端元素*/
  .footer_area .flag_m{ display: none;}
  .back_to_top{ display:none;}

  //////////////////
  ///
  ///
  ///

@media screen and (max-width: 768px) {
  .wrap_footer{ height: auto;}
  .footer_con{ width:100%; height: auto;}
  .footer_con .title{ font-size: 16px; margin-left: 30px; margin-right: 21px; margin-bottom: 0px;}
  .footer_con .tit_area{ display: block;  height: 52px; line-height: 52px; position: relative;}
  .footer_con .join_list,.footer_con .contact_list,.footer_con .legal_list{ display:block; height: 0; transition: height 0.3s ease-out;}
  .footer_con .join_list li a,.footer_con .contact_list li a,.footer_con .legal_list li a{ margin-bottom: 0px; padding: 9px 0; margin-left: 30px; font-size: 14px;}
  .footer_area .links{ display:none;}
  .footer_con .focus_list{ display:block;}
  .footer_con .focus_us .focus_list li a{ margin-bottom: 0px;}
  .footer_con .tit_area .icon{ display: block; height:12px; width: 12px; background:url(./assets/mobile/icon_arrow_d.png) no-repeat 0 0; background-size: contain; position: absolute; right: 28px; top: 20px; transition:transform 0.2s ease-out;}
  .footer_con .logo_9cloud{ display:none;}

  .join_us, .contact_us, .legal_info{ width:100%; float: none; overflow: hidden;}
  .legal_info{ margin-bottom:18px;}

  .focus_us{ padding-top:41px; border-bottom: none; height: 22px; margin-bottom: 18px;}
  .focus_us .focus_list li{ margin-right: 23px;}
  .focus_us .focus_list li a{ display:block; height: 22px; width: 22px;}
  .focus_us .focus_list li a .icon{ display:block; height: 22px; width: 22px;}

  .footer_con .sublist_show ul{ height:117px;}
  .footer_con .contact_us.sublist_show ul{
    height: 148px;
  }
  .footer_con .sublist_show .tit_area .icon{ transform:rotate(180deg);}

  .footer_area{ position: static; padding-bottom: 29px;}
  .footer_area .copyright{ color: #929797; font-size: 12px; margin: 0px 30px; float: none; line-height: 1.8;}
  .footer_area .flag_m{ display:block;}
  .footer_area .copyright .flag_num{ display: inline-block; color: #929797;display: block;}
  .footer_area .copyright .flag_num span{
    margin-left: 20px;
  }

  .back_to_top{ display:block; width: 48px; height: 48px;}
  .back_to_top .back{ display:block; height: 50px; width: 100%; text-align: center; font-size: 16px; color: #111; background: #f2f3f5; line-height: 50px;}
  /*--------------------------------------------------------------------------------*/
.ten_totop{
    display: none; background:#FBFBFB;
    padding: 12px 24px; text-align: center;
}
.ten_totop--float{
    display: block; position: fixed; bottom:-200px; right: 40px; z-index: 98;
    width:60px; height: 60px; transition: ease all 0.3s;
    background: #FFF; border-radius: 50%;;
    border:1px solid #0052D9;
    padding: 0px;
}
.ten_totop--float:after{
    content: "";
    display: block; width: 12px; height: 12px;
    position: absolute; top:calc(50% - 3px); left:calc(50% - 6px);
    transform: rotate(-135deg);
    border-bottom: 1px solid #0052D9;
    border-right: 1px solid #0052D9;;
}
.ten_totop--float:hover{ background: #0052D9;; }
.ten_totop--float:hover:after{
    border-bottom: 1px solid #FFF;
    border-right: 1px solid #FFF;
}
.ten_totop--float--show{ bottom:40px; }
}
/////////////
/*--------------------------------------------------------------------------------*/
.ten_totop{
    display: none; background:#FBFBFB;
    padding: 12px 24px; text-align: center;
}
.ten_totop--float{
    display: block; position: fixed; bottom:-200px; right: 40px; z-index: 98;
    width:60px; height: 60px; transition: ease all 0.3s;
    background: #FFF; border-radius: 50%;;
    border:1px solid #0052D9;
    padding: 0px;
    cursor: pointer;
}
.ten_totop--float:after{
    content: "";
    display: block; width: 12px; height: 12px;
    position: absolute; top:calc(50% - 3px); left:calc(50% - 6px);
    transform: rotate(-135deg);
    border-bottom: 1px solid #0052D9;
    border-right: 1px solid #0052D9;;
}
.ten_totop--float:hover{ background: #0052D9;; }
.ten_totop--float:hover:after{
    border-bottom: 1px solid #FFF;
    border-right: 1px solid #FFF;
}
.ten_totop--float--show{ bottom:40px; }
}
</style>
