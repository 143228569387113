<template>
<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <!-- <img src="https://www.hamk.fi/wp-content/uploads/2020/12/DataAnalytiikka-scaled.jpeg" class="d-block w-100" alt="..."> -->
      <img src="../assets/banner/DataAnalytiikka-scaled.jpeg" class="d-block w-100" alt="数据">
      <div class="ten_banner_shadow"/>
      <div class="ten_banner_text">
        <div class="ten_main">
          <h2>数据如何作为生产要素, 创造价值?</h2>
          <h5>基于数据+算力+算法可以对物理世界进行描述、原因分析、结果预测、科学决策。</h5>
        </div>
      </div>
    </div>
    <div class="carousel-item">
      <!-- <img src="https://content.thriveglobal.com/wp-content/uploads/2019/12/Efficiency-Productivity.png" class="d-block w-100" alt="..."> -->
      <img src="../assets/banner/Efficiency-Productivity.jpg" class="d-block w-100" alt="效率">
      <div class="ten_banner_shadow"/>
       <div class="ten_banner_text">
        <div class="ten_main">
          <h2>效率, 就是竞争力</h2>
          <h5>提高效率的关键在于选择合适的工具。工欲善其事必先利其器, 善用工具, 事半功倍。</h5>
        </div>
      </div>
    </div>
    <div class="carousel-item">
      <!-- <img src="https://www.munichre.com/content/dam/munichre/global/images/royalty-free/AdobeStock_323792539.jpeg/_jcr_content/renditions/cropped.16_to_9.jpeg./cropped.16_to_9.jpeg" class="d-block w-100" alt="..."> -->
      <!-- <img src="https://blog.infraspeak.com/wp-content/uploads/2021/08/Maintenance-as-a-Service.jpeg" class="d-block w-100" alt="..."> -->
      <img src="../assets/banner/Maintenance-as-a-Service.jpeg" class="d-block w-100" alt="专业">
      <div class="ten_banner_shadow"/>
       <div class="ten_banner_text">
        <div class="ten_main">
          <h2>专业乃立身之本</h2>
          <h5>已累计为国内外超过400家高星级酒店提供产品及服务。</h5>
        </div>
      </div>
    </div>
    <div class="carousel-item">
      <!-- <img src="https://www.gsa.gov/cdnstatic/PS-Handshake-2000x1222.jpg" class="d-block w-100" alt="..."> -->
      <img src="../assets/banner/PS-Handshake-2000x1222.jpeg" class="d-block w-100" alt="共赢">
      <div class="ten_banner_shadow"/>
       <div class="ten_banner_text">
        <div class="ten_main">
          <h2>开放、合作、共赢</h2>
          <h5>我们将持续深耕于酒店行业, 聚焦用户, 与合作伙伴共建数字酒店生态。</h5>
        </div>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
  }
}
</script>

<style lang="scss" scoped>
.ten_banner_shadow{
  display: block;
  position: absolute;
  top:0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
}
.ten_banner_shadow--20 { background: rgba(0,0,0,0.1); }
.ten_banner_shadow--30 { background: rgba(0,0,0,0.2); }

.carousel-indicators [data-bs-target] {
  height: 10px;
  width: 10px;
  transition: all 0.1s linear;
}

.carousel-indicators .active {
  opacity: 1;
  background-color: #044c81;
  width: 30px;
  transition: all 0.1s linear;
}

.carousel-item {
  height: 720px;

  img {
    height: 720px;
    object-fit: cover;
  }
}

.ten_banner_text{
  position: absolute;
  top:0px;
  padding-bottom: 120px;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.1);
  color:#FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ten_banner_text h2{ max-width: 950px; font-size: 48px; }
.ten_banner_text h3{ max-width: 480px; }
.ten_banner_text h4, .ten_banner_text h6, .ten_banner_text p{ max-width: 480px; }
.ten_banner_text span { width: 550px; }

.ten_banner_text h5{font-size:18px;line-height: 1.8em;margin:12px 0px;padding: 0px;font-weight: normal;}

.ten_main{ width: 100%; max-width: 1200px; margin: 0px auto; }
@media screen and (max-width: 1248px){ .ten_main{ margin: 0px 24px;  width: auto; } }
</style>
