import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@popperjs/core/dist/umd/popper'
import 'animate.css'
import 'bootstrap/dist/css/bootstrap.css'
import './styles/index.scss'
// import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'bootstrap-icons/font/bootstrap-icons.css'

createApp(App).use(store).use(router).mount('#app')
